import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/breadcrumb'
import IndexPage from '../components/indexPage'

const ConceptionPage = ({location}) => {
  return (
    <Layout simpleHeader={true} url="/ecoconception" type="index">
      <SEO 
        title="Meilleurs sites écoresponsables de l'Observatoire"
        description="Quels sont les meilleurs sites écoresponsables de l'Observatoire de l'impact positif ? Du simple bon sens au Green IT, retrouvez les sites avec la meilleure écoconception."
        path="/ecoconception" />

      <Breadcrumb url="/ecoconception" label="Éco-onception" />   

      <h1 className="sr-only">Écoconception</h1>
      <IndexPage slug="conception" type="index" />
    </Layout>
  )
  }

export default ConceptionPage

